@tailwind base;
@tailwind components;
@tailwind utilities;

.color-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
}

.color-input::-moz-color-swatch {
  border-radius: 50%;
  border: none;
}

.color-input::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-input::-webkit-color-swatch {
  border: 0;
  border-radius: 50%;
}

.color-input::-moz-color-swatch,
.color-input::-moz-focus-inner {
  border: 0;
}

.color-input::-moz-focus-inner {
  padding: 0;
}
