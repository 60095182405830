.fc .fc-timegrid-slot-minor {
  border: none;
}

.fc-today {
  background: red !important;
}

.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
  border: none;
}

.fc-timegrid-slot-label-cushion {
  color: #70757a;
  font-size: 10px;
  -webkit-font-smoothing: subpixel-antialiased;
  background: white;
  position: relative;
  top: 10px;
  left: 0px;
  padding: 0 4px 0 4px;
}

.fc-timegrid-now-indicator-arrow:after {
  content: '';
  position: relative;
  top: 6px;
  left: 6px;
  color: inherit;
  font-size: 24px;
}

.CalendarContainer {
  position: relative;
}
.CalendarContainer.assignOpen .fc-left {
  padding-left: 80px;
}
.CalendarContainer .fc-filters {
  position: absolute;
  left: 44px;
  top: 8px;
}
.CalendarContainer .fc-menus {
  position: absolute;
  right: 16px;
  top: 8px;
}
.CalendarContainer .fc-tickets {
  position: absolute;
  left: 6px;
  top: 10px;
}
.CalendarContainer .fc-tickets i {
  font-size: 19px;
}
/* This is the Material Design theme for FullCalendar Weekly Agenda view Creation Date: Aug 19th 2015 Author: Jacky Liang Version: FullCalendar 2.4.0 Tested Using the Following FC Settings: editable: false, handleWindowResize: true, weekends: false, // Hide weekends defaultView: 'agendaWeek', // Only show week view header: false, // Hide buttons/titles minTime: '07:30:00', // Start time for the calendar maxTime: '22:00:00', // End time for the calendar columnFormat: {
  week: 'ddd' // Only show day of the week names 
}
, displayEventTime: true, allDayText: 'Online/TBD' Note: This has NOT been tested on Monthly or Daily views. Colors: Use the following - https://www.google.com/design/spec/style/color.html#color-color-palette at the 700 level. An opacity of 0.65 is automatically applied to the 700 level colors to generate a soft and pleasing look. Color were applied to each event using the following code: events.push({
  title: 'This is a Material Design event!', start: 'someStartDate', end: 'someEndDate', color: '#C2185B' 
}
);
*/
.fc-header-toolbar {
  justify-content: right;
  padding: 12px 0 0 12px;
}
.fc-content {
  overflow: visible;
  height: 100%;
}
.fc-center {
  margin-left: auto;
  padding-right: 16px;
}
.fc-center h2 {
  line-height: 1;
  font-size: 16px;
  padding-right: 54px;
}
.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: none;
}
/* Remove that awful yellow color and border from today in Schedule */
.fc-state-highlight {
  opacity: 0;
}
/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: 0.65;
  left: 5% !important;
  right: 5% !important;
}
/* Bolds the name of the event and inherits the font size */
.fc-event {
  border: none;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  position: relative;
  font-size: inherit !important;
  font-weight: bold !important;
  border-radius: 6px;
}
.fc-ltr .fc-axis {
  position: relative;
}
/* Remove the header border from Schedule */
.fc td,
.fc th {
  border-width: 1px !important;
  vertical-align: top !important;
}
.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: 'none';
}
.fc-time-grid-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.fc-time-grid-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.fc-time-grid-container::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #263238;
}
/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: 0.25 !important;
}
/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: normal !important;
}
/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}
/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}
/* Material design button */
.fc-today-button,
.fc-button-primary:disabled,
.fc-today-button:hover {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #dadce0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 36px;
  margin: 7px 12px 5px 12px;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: none;
  opacity: 1;
  color: #3c4043;
  min-width: 88px;
  padding: 0 14px;
}
.fc-today-button:hover {
  background-color: #f1f3f4;
}
.fc-prev-button,
.fc-next-button,
.fc-prev-button:hover,
.fc-next-button:hover,
.fc-prev-button:focus,
.fc-next-button:focus,
.fc-prev-button:active,
.fc-next-button:active,
.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  background-color: transparent;
  color: #2c3e50;
  border: 0;
}
.fc-button-primary:focus {
  box-shadow: none;
}
/* The active button box is ugly so the active button will have the same appearance of the hover */
/* Not raised button */
.fc-state-default {
  box-shadow: None;
}
.fc-time-grid .fc-now-indicator-line {
  left: 0;
  right: 0;
}
.fc th,
.fc-head-container.fc-widget-header {
  border-top: 0 !important;
  padding-bottom: 4px;
}
.fc-now-indicator.fc-now-indicator-line::before {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: '';
  position: absolute;
  height: 12px;
  margin-left: -6.5px;
  margin-top: -6.5px;
  width: 12px;
  z-index: 505;
}
.fc-today .day-int {
  font-size: 25px;
  letter-spacing: -2.5px;
  text-indent: -2.5px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum' 1;
  font-feature-settings: 'tnum' 1;
  color: white;
}
.fc-center h2 {
  text-transform: capitalize;
  color: #3c4043;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}
.day-int {
  position: relative;
  z-index: 2;
  font-size: 26px;
  letter-spacing: -2.6px;
  text-indent: -2.6px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum' 1;
  font-feature-settings: 'tnum' 1;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #3c4043;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  line-height: 46px;
  height: 46px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8px;
  width: 46px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.day-tickets {
  font-size: 11px;
  font-weight: 100;
}
.day-text {
  line-height: 32px;
  position: relative;
  z-index: 2;
  color: #70757a;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-left: 0;
  margin-top: 8px;
  text-indent: 0.8px;
  text-transform: uppercase;
}
.fc-toolbar.fc-header-toolbar {
  display: none !important;
  margin-bottom: 10px;
  padding: 6px 0 0 0px;
}
.fc-left {
  padding-left: 80px;
}
.fc-title {
  font-weight: 400;
}
.fc-time-grid .fc-slats td {
  height: 22px;
}
.calendar-black .fc-now-indicator {
  border: 0 solid #212121;
}
.calendar-black .fc-time-grid .fc-now-indicator-line {
  border-top: #212121 solid 2px;
}
.calendar-black .fc-today .day-text {
  color: #212121;
}
.calendar-black .fc-now-indicator.fc-now-indicator-line::before {
  background: #212121;
}
.calendar-black .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #212121;
}
.calendar-black .fc-today .day-int:after {
  content: '';
  background-color: #212121;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-yellow .fc-now-indicator {
  border: 0 solid #036;
}
.calendar-yellow .fc-time-grid .fc-now-indicator-line {
  border-top: #036 solid 2px;
}
.calendar-yellow .fc-today .day-text {
  color: #036;
}
.calendar-yellow .fc-now-indicator.fc-now-indicator-line::before {
  background: #036;
}
.calendar-yellow .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #036;
}
.calendar-yellow .fc-today .day-int:after {
  content: '';
  background-color: #036;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-pink .fc-now-indicator {
  border: 0 solid #fc427b;
}
.calendar-pink .fc-time-grid .fc-now-indicator-line {
  border-top: #fc427b solid 2px;
}
.calendar-pink .fc-today .day-text {
  color: #fc427b;
}
.calendar-pink .fc-now-indicator.fc-now-indicator-line::before {
  background: #fc427b;
}
.calendar-pink .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #fc427b;
}
.calendar-pink .fc-today .day-int:after {
  content: '';
  background-color: #fc427b;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-ttg .fc-now-indicator {
  border: 0 solid #f57e20;
}
.calendar-ttg .fc-time-grid .fc-now-indicator-line {
  border-top: #f57e20 solid 2px;
}
.calendar-ttg .fc-today .day-text {
  color: #f57e20;
}
.calendar-ttg .fc-now-indicator.fc-now-indicator-line::before {
  background: #f57e20;
}
.calendar-ttg .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #f57e20;
}
.calendar-ttg .fc-today .day-int:after {
  content: '';
  background-color: #f57e20;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-red .fc-now-indicator {
  border: 0 solid #e63d53;
}
.calendar-red .fc-time-grid .fc-now-indicator-line {
  border-top: #e63d53 solid 2px;
}
.calendar-red .fc-today .day-text {
  color: #e63d53;
}
.calendar-red .fc-now-indicator.fc-now-indicator-line::before {
  background: #e63d53;
}
.calendar-red .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #e63d53;
}
.calendar-red .fc-today .day-int:after {
  content: '';
  background-color: #e63d53;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-bobdesk .fc-now-indicator {
  border: 0 solid #827596;
}
.calendar-bobdesk .fc-time-grid .fc-now-indicator-line {
  border-top: #827596 solid 2px;
}
.calendar-bobdesk .fc-today .day-text {
  color: #827596;
}
.calendar-bobdesk .fc-now-indicator.fc-now-indicator-line::before {
  background: #827596;
}
.calendar-bobdesk .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #827596;
}
.calendar-bobdesk .fc-today .day-int:after {
  content: '';
  background-color: #827596;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-blue .fc-now-indicator {
  border: 0 solid #1facda;
}
.calendar-blue .fc-time-grid .fc-now-indicator-line {
  border-top: #1facda solid 2px;
}
.calendar-blue .fc-today .day-text {
  color: #1facda;
}
.calendar-blue .fc-now-indicator.fc-now-indicator-line::before {
  background: #1facda;
}
.calendar-blue .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #1facda;
}
.calendar-blue .fc-today .day-int:after {
  content: '';
  background-color: #1facda;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.calendar-green .fc-now-indicator {
  border: 0 solid #31b559;
}
.calendar-green .fc-time-grid .fc-now-indicator-line {
  border-top: #31b559 solid 2px;
}
.calendar-green .fc-today .day-text {
  color: #31b559;
}
.calendar-green .fc-now-indicator.fc-now-indicator-line::before {
  background: #31b559;
}
.calendar-green .fc-today .day-int {
  position: relative;
  background-color: transparent;
  color: #31b559;
}
.calendar-green .fc-today .day-int:after {
  content: '';
  background-color: #31b559;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
}
.fc-bob:not(.preview-wrapper) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  padding: 6px 11px;
  border-radius: 6px;
  font-family: 'Nunito Sans', sans-serif;
  height: 100%;
}
.fc-bob:not(.preview-wrapper) strong {
  font-size: 12px;
  font-weight: 600;
}
.fc-bob:not(.preview-wrapper) span {
  font-size: 12px;
}
.fc-bob:not(.preview-wrapper) span.bandeauLeft {
  width: 6px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 6px 0px 0px 6px;
}
@media screen and (max-width: 524px) {
  .fc-today-button {
    display: none;
  }
  .CalendarContainer .fc-filters {
    top: 6px;
  }
  .CalendarContainer .fc-menus {
    top: 4px;
  }
}
.date_type {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 4px;
}
